import { Link } from 'react-router-dom';
import './home.css';
import second from './images/malesleep.webp';
import first from './images/female_tmj.webp';
import Appointments from './pages/appointments';
import Our_Team from './pages/our_team';
import TMJ_info from './pages/tmj_info';
import Footer from './Footer';
import Button from '@mui/material/Button';
import Sleep_apnea from './pages/sleep_apnea';
import Contact from './pages/contact';
import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar';
import Logo1 from './images/logo_final_border.png';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import expert from './images/expert.png';
import tech from './images/tech.jpg';
import personal from './images/personal.jpg';
import comp from './images/comp.jpg';
import Palm from './images/indian-canyon-golf-resort-02.webp';

import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import CardActions from '@mui/joy/CardActions';
// import IconButton from '@mui/joy/IconButton';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

import Drawer from './component/Drawer';


function Home () {

    const reviews = [" ' I was referred to Dr. Lee because I am having difficulty using the CPAP for my sleep apnea, and I have jaw pain on top of that. He gave me a very thorough review of my condition and explained everything clearly, including potential side affects. Looking forward to beginning treatment. ' ", " ' The office is beautiful, the assistants are friendly, and the physician is very attentive and patient-oriented. He is also very knowledgeable,  I have been through multiple doctors, each could not tell me what was wrong, but this one told me everything within a few minutes and was very thorough. Also, a lot of doctors don't take the time to listen to me but this physician did and I felt very comfortable. ' ", " ' I am extremely pleased with the great service of the front office staff. I was seen immediately and my issue was totally resolved. My sleep has improved since going to this office. Thank you for your efficient, knowledgeable and kind service! ' ", " ' I'm glad that my primary doctor referred me to this center, I've been seen for several months because of issues with TMJ & bite, I really like the care that I have received with here. The staff is very friendly, helpful, accommodating, and knowledgeable. ' "];
    const [count, setCount] = useState(0);
    const [lst, setLst] = useState([reviews[0]]);
    
    function goBack(e) {
        e.preventDefault()
        
        if (count > 0) {
            setLst (reviews[count - 1])
            setCount (count- 1)
        }
        if (count == 0) {
            setLst (reviews[3])
            setCount (3)
        }
        const copyDiv = document.querySelector('.not-ani:not(.text-focus-in2)')
        if(copyDiv) {
            copyDiv.classList.add('text-focus-in2');
            copyDiv.addEventListener('animationend', () => copyDiv.classList.remove('text-focus-in2') );
        }
    }

    function goForward(e) {
        e.preventDefault()
        
        if (count < 3) {
            setLst (reviews[count + 1])
            setCount (count + 1)
        }
        if (count == 3) {
            setLst (reviews[0])
            setCount (0)
        }
        const copyDiv = document.querySelector('.not-ani:not(.text-focus-in2)')
        if(copyDiv) {
            copyDiv.classList.add('text-focus-in2');
            copyDiv.addEventListener('animationend', () => copyDiv.classList.remove('text-focus-in2') );
        }
    
    }

    return (
        <>
        <div className="header">
            <div>
                <div className='container1' data-aos="fade-down">
                    <div className='mobile-header'>
                    <Link to='/'>
                        <div>
                            <img id='logo-final' src={Logo1} alt="head" />
                        </div>
                        <div className='logo-name'>
                            <h2 id='logo-name'>TMJ Head & Neck Pain Center</h2>
                            <h3 id='logo-subtitle'>also treating sleep apnea</h3>
                        </div>
                    </Link>
                    </div>
                    
                    <div className='nav'>
                        <div className='nav-sections'>
                            
                            
                            <div>
                                <Link to='/tmj' element={<TMJ_info/>}><a id='tmj-home'>TMJ</a></Link>
                            </div>
                            <div>
                                <Link to='/sleep_apnea' element={<Sleep_apnea/>}><a id='sleepapnea'>Sleep Apnea</a></Link>
                            </div>
                            <div>
                                <Link to='/about_us' element={<Our_Team/>}><a id='ourteam'>About Us</a></Link>
                            </div>
                            <div>
                                <Link to='/contact' element={<Contact/>}><a id='contact'>Contact</a></Link>
                            </div>
                            <div>
                                <a href="https://app.nexhealth.com/appt/tmj-head-and-neck-pain-center" id='button-nav'>Appointments</a>
                            </div>
                        </div>
                        <div className='navbar-home1'>
                            {/* <Navbar id='navbar-home1'></Navbar> */}
                            <Drawer/>
                        </div>
                        
                    </div>
                </div>
                
                <div className='container2' data-aos="fade-up">
                    <h2 id='header-text'>Find Your Smile Again</h2>
                    <div className='call-to-action' id='underline-box'>
                        <a href="https://app.nexhealth.com/appt/tmj-head-and-neck-pain-center" element={<Appointments/>}><h6 id='underline'>Make an appointment today</h6></a>
                    </div>
                </div>
            </div>
            {/* this helps create the curved line */}
            <div class="custom-shape-divider-bottom-1687213582">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z" class="shape-fill"></path>
                </svg>
            </div>
        </div> 

        {/* --- BODY SECTION --- */}

        
        <div className='body-one' data-aos="fade-up"  data-aos-duration="5000">
            <h1 id='body-one-paragraph'>TMJ, Headache, Sleep Apnea, and Jaw Pain Relief in the Coachella Valley</h1>
        </div>
        <div className='body-one-words'>
            <div className='body-one-paragraph2' data-aos="fade-up">
                <h3 id='body-one-paragraph2'>Experience <strong>Lasting Relief and Restful Sleep</strong>  with Expert Dental Care</h3>
            </div>
            <br />
            <div className='body-one-paragraph2' data-aos="fade-up">
                    <h3 id='body-one-paragraph2'>Providing expert care for TMJ/TMD, headaches, sleep apnea, and jaw pain across <strong>Rancho Mirage, Palm Desert, Palm Springs, Cathedral City, Indian Wells, La Quinta, Indio, Redlands</strong>, and nearby communities.</h3>
            </div>
            <br />
            <div className='body-one-paragraph2' data-aos="fade-up">
                <h3 id='body-one-paragraph2'>At TMJ Head & Neck Pain Center, we specialize in comprehensive care for Temporomandibular Joint (TMJ) disorders and sleep apnea, <strong>utilizing advanced techniques and personalized solutions to enhance your overall well-being.</strong></h3>
            </div>
        </div>
        
        <div className='body-two'>
            <h2 id='body-one-paragraph'>Why Choose Us for TMJ and Sleep Apnea Care?</h2>
            <div className='cards'>
                <div className='cards2'>
                    <div>
                            <Card id='card1' sx={{ maxWidth: 545 }} data-aos="fade-in">
                            <CardMedia
                            component="img"
                            height="240"
                            image={expert}
                            alt="consultation"
                            loading='lazy'
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                            <div id='card-header'>
                            Expertise
                            </div>
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                            <div id='card-paragraph'>
                            Our team comprises experienced professionals dedicated to the diagnosis and treatment of TMJ disorders and sleep apnea. We make your health a priority.
                            </div>
                            </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                            <Card id='card1' sx={{ maxWidth: 545 }} data-aos="fade-in">
                            <CardMedia
                            component="img"
                            height="240"
                            image={personal}
                            alt="dentist office"
                            loading='lazy'
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                            <div id='card-header'>
                            Personalized Approach
                            </div>
                            
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                            <div id='card-paragraph'>
                            We understand that each patient's needs are unique. Our customized treatment plans cater to your specific conditions for effective relief and better sleep.
                            </div>
                            </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
                <div className='cards2'>
                    <div>
                            <Card id='card1' sx={{ maxWidth: 545 }} data-aos="fade-in">
                            <CardMedia
                            component="img"
                            height="240"
                            image={tech}
                            alt="dental tools"
                            loading='lazy'
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                            <div id='card-header'>
                            Cutting-Edge Technology
                            </div>
                            
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                            <div id='card-paragraph'>
                            We leverage state-of-the-art equipment and the latest advancements in TMJ and sleep apnea therapies to provide the highest quality care.
                            </div>
                            
                            </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div>
                            <Card id='card1' sx={{ maxWidth: 545 }} data-aos="fade-in">
                            <CardMedia
                            component="img"
                            height="240"
                            image={comp}
                            alt="smile"
                            loading='lazy'
                            />
                            <CardContent>
                            <Typography gutterBottom variant="h4" component="div">
                            <div id='card-header'>
                            Comprehensive Services
                            </div>
                            
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                            <div id='card-paragraph'>
                            From conservative treatments to advanced therapies, we offer a full spectrum of options for TMJ and sleep apnea management.
                            </div>
                            
                            </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>

        <div className='body'>
            <div className='body1' >
                <div className='first'>
                    <img id='first' alt='tmj pain' loading='lazy' src={first}></img>
                </div>
                <div className='first-words' data-aos="fade-in">
                    <h2 id='body1-words'>No More Chronic Pain</h2>
                    <h3 id='body1-words5'>Are you living with jaw pain or stiffness? Have you faced unexplained ear problems or vertigo that won’t go away? Do you hear or feel a clicking, snapping or popping sound when you eat, talk or yawn or have you suffered from intense head and facial pain, but no one seems to know why?</h3>
                    <a href='/tmj' id='sleepapnea5'>Learn more about TMJ</a>
                </div>
            </div>
            <div className='body2'>
                <div className='second-words' data-aos="fade-in">
                    <h2 id='body1-words'>Sleep Apnea and TMJ Treatment – Restful Sleep and Pain Relief</h2>
                    <h3 id='body1-words5'>Untreated sleep apnea can lead to various health concerns, including jaw pain and headaches. Our office provides customized solutions for sleep apnea and TMJ disorders throughout the Coachella Valley, including Desert Hot Springs, Palm Springs, and Rancho Mirage. Let us help you breathe and sleep better.</h3>
                    <a href='/sleep_apnea' alt='man sleeping' id='sleepapnea6'>Learn more about sleep</a>
                </div>
                <div className='second'>
                    <img id='second' alt='man sleeping' loading='lazy' src={second}></img>
                </div>
            </div>
        </div>

        <div className='make-app-footer2'>
            <h2 id='make-app-footer-title2' data-aos="fade-in">Comprehensive TMJ and Sleep Apnea Treatment Across Coachella Valley</h2>
            <br />
            <h3 id='body1-words2'>
            If you're experiencing symptoms like a headache behind the ear, TMJ ear pain, sleep apnea, or wondering, "Why does my jaw pop?", our dental office proudly serves the following cities:
            </h3>
            <h3 id='body1-words2'>
                <strong>Rancho Mirage, Palm Desert, Cathedral City, Indian Wells, La Quinta, Indio, Desert Hot Springs, Yucca Valley, Joshua Tree, Redlands</strong>
            </h3>
            <div className='locations-container'>
                {/* <div className='locations'>
                    <ul>
                    <li>Rancho Mirage</li>
                    <li>Palm Desert</li>
                    <li>Palm Springs</li>
                    <li>Cathedral City</li>
                    <li>Indian Wells</li>
                    <li>La Quinta</li>
                    <li>Indio</li>
                    <li>Desert Hot Springs</li>
                    <li>Yucca Valley</li>
                    <li>Joshua Tree</li>
                    </ul>
                </div> */}
                <div>
                    <img id='locations-pic' src={Palm} alt="" />
                </div>
            </div>
            
            <h3 id='body1-words2'>
No matter where you are in the Coachella Valley, our specialized treatments for TMJ, sleep apnea, and related conditions are just a call away.
            </h3>
            <br />
            <br />
        </div>

        <div class="custom-shape-divider-bottom-1736481700">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
            </svg>
        </div>
        <div className='reviews-bottom-page'>
            <div className='what-people-saying'>
                <h2 data-aos="fade-in">What People Are Saying</h2>
            </div>
            <div className='white'>
                
                <div className='slider-test' data-aos="fade-in">  
                    <div className='reviews'>
                        <h3 id='reviews' className="not-ani">{lst}</h3>
                    </div>
                </div>
                
            </div>
            <div className='arrows'>
                <div>
                    <IoIosArrowBack id='arrow-button-back' onClick={goBack} data-aos="fade-in"/>
                </div>
                <div>
                    <IoIosArrowForward id='arrow-button-forward' onClick={goForward} data-aos="fade-in"/>
                </div>
            </div>
            <div className='more-reviews'>
                <a id='more-reviews' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu' target="_blank" data-aos="fade-in">Even More Reviews <IoIosArrowForward id='forward-arrow-2'/></a>
            </div>
        </div>
        <div class="custom-shape-divider-top-1736481314">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
        </div>
        <div className='make-app-footer3'>
            <h2 id='make-app-footer-title2' data-aos="fade-in">Schedule Your Consultation</h2>
            <br />
            <h3 id='body1-words2'>
            Conveniently located in the heart of the Coachella Valley, our dental office welcomes patients from all surrounding cities. Contact us today to schedule your consultation and discover effective, personalized treatments for TMJ, sleep apnea, and related conditions.
            </h3>
            <div className='button-nav1'>
                <a href="https://app.nexhealth.com/appt/tmj-head-and-neck-pain-center" element={<Appointments/>} id='button-nav1' data-aos="fade-in">Get Started</a>
            </div>
        </div>
        
        <div className='footer'>
            <div id='footer2' data-aos="fade-in">
                <div id='bottom' data-aos="fade-in">
                    <div className='logo-name1'>
                        <Link to='/'><h2 id='logo-name4'>TMJ Head & Neck Pain Center</h2></Link>
                        <h3 id='logo-subtitle3'>also treating sleep apnea</h3>
                    </div>
                    <br />
                </div>
                <div className='footer-hours-time2' data-aos="fade-in">
                    <h4>Contact:</h4>
                    <h4 id='phone'>Office: 760-770-4033</h4>
                    <h4 id='phone'>Fax: 760-770-3975</h4>
                </div>
                <br />
                <hr />
                <div className='google-location' data-aos="fade-in">
                    <h4>Rancho Mirage Location:</h4>
                    <a id='bottom-font' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu'><h5>35900 Bob Hope Dr., Suite 110</h5></a>
                    <a id='bottom-font' href='https://www.google.com/maps/place/TMJ+Head+%26+Neck+Pain+Center/@33.7895759,-116.4075622,17z/data=!3m1!4b1!4m6!3m5!1s0x80dafd430cff43ff:0xe695f17553c469da!8m2!3d33.7895759!4d-116.4053735!16s%2Fg%2F1tjv3t8v?entry=ttu'><h5>Rancho Mirage, CA 92270</h5></a>
                </div>
                
                <br></br>
                <div className='footer-hours' data-aos="fade-in">
                    <h4>Hours:</h4>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Monday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Tuesday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Wednesday:</h5>
                        </div>
                        <div>
                            <h5>By Appointment</h5>
                        </div>
                    </div>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Thursday:</h5>
                        </div>
                        <div>
                            <h5>8:00 AM - 5:00 PM</h5>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className='google-location' data-aos="fade-in">
                    <h4>Redlands Location:</h4>
                        <a id='bottom-font' href='https://www.google.com/maps/place/2048+Orange+Tree+Ln+%23100,+Redlands,+CA+92374/@34.0674418,-117.2265649,17z/data=!3m1!4b1!4m6!3m5!1s0x80dcababacea5a7f:0xa9e12051a9beadcc!8m2!3d34.0674418!4d-117.22399!16s%2Fg%2F11q_64czj5?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D'><h5>2048 Orange Tree Ln., Ste 100</h5></a>
                        <a id='bottom-font' href='https://www.google.com/maps/place/2048+Orange+Tree+Ln+%23100,+Redlands,+CA+92374/@34.0674418,-117.2265649,17z/data=!3m1!4b1!4m6!3m5!1s0x80dcababacea5a7f:0xa9e12051a9beadcc!8m2!3d34.0674418!4d-117.22399!16s%2Fg%2F11q_64czj5?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D'><h5>Redlands, CA 92374</h5></a>
                    </div>
                    <br />
                    <h4>Hours:</h4>
                    <div className='footer-hours-time'>
                        <div>
                            <h5 id='footer-day'>Friday:</h5>
                        </div>
                        <div>
                            <h5>By Appointment</h5>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
        </div>
        </>
    )
};

export default Home;