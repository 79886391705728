import './tmj_info.css'
import Footer from '../Footer';
import Header from '../Header';
import Appointments from './appointments';
import { Link } from 'react-router-dom';
import Our_Team from './our_team';
import TMJ_info from './tmj_info';
import Sleep_apnea from './sleep_apnea';
import Contact from './contact';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React, { useState } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';


function Tmj_info () {
        const [visible, setVisible] = useState(false)
        
        const toggleVisible = () => {
          const scrolled = document.documentElement.scrollTop;
          if (scrolled > 300){
            setVisible(true)
          } 
          else if (scrolled <= 300){
            setVisible(false)
          }
        };
        
        const scrollToTop = () =>{
          window.scrollTo({
            top: 0, 
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
          });
        
        window.addEventListener('scroll', toggleVisible);}

    return (
        <>
        <div>
            <Header/>
        </div>
      <div className='accord' data-aos="fade-in">
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography><h1 id="accord-title">What is TMJ?</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>"TMJ" are the first letters of the three anatomic parts of the head.
T= Temporal bone (skull), M= Mandible (lower jaw), J= Joint (connects two bones together) and called temporo-mandibular-joint.
                </h3>
                <h3 id='tmj-paragraph'>
                The TMJ is one of the most complex joints in the body. It is composed of a large group of delicate small muscles, nerves, blood vessels, ligaments, tendons, cartilage, disc, synovial fluid, retrodiscal tissue: each part works in harmony with other parts much like a symphony orchestra.
                </h3>
                <h3 id='tmj-paragraph'>
                All body joints (neck, shoulder, back, hip, knee, wrist, elbow, ankle, finger) have hinge movement or circular movement or a combination of both. However, TMJ has not only hinge and circular movement, it also has a gliding movement, in and out of the socket (glenoid fossa): we call it "Ginglymoarthroidal joint".
                The function of the TMJ is to hold the lower jaw to the head within a certain boundary, and guide the jaw movements for chewing, talking, singing, yawning, breathing, swallowing, keeping the airway open while sleeping, and maintaining body posture and balance, etc.
                </h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography><h1 id="accord-title">Causes</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>
                The exact causes of TMJ disorder are often multifactorial and can vary from person to person. Here are some common factors that can contribute to the development of TMJ disorder:</h3>
                <h3 id='tmj-paragraph'>
                1. <strong>Jaw misalignment:</strong> Problems with the alignment or positioning of the jaw joint (temporomandibular joint) can lead to TMJ disorder. This misalignment can occur due to factors like trauma or injury to the jaw, improper dental work, or developmental abnormalities.
                </h3>
                <h3 id='tmj-paragraph'>
                2. <strong>Jaw clenching and teeth grinding (bruxism):</strong> Persistent clenching or grinding of the teeth, which often occurs during sleep, can exert excessive pressure on the jaw joint. Over time, this can contribute to the development of TMJ disorder.
                </h3>
                <h3 id='tmj-paragraph'>
                3. <strong>Arthritis:</strong> Various forms of arthritis, such as osteoarthritis or rheumatoid arthritis, can affect the temporomandibular joint and lead to TMJ disorder. Arthritis causes inflammation and damage to the joint structures, resulting in pain and dysfunction.
                </h3>
                <h3 id='tmj-paragraph'>
                4. <strong>Trauma or injury:</strong> A direct impact or injury to the jaw area, such as from a sports injury, motor vehicle accident, or a fall, can damage the temporomandibular joint or surrounding structures, leading to TMJ disorder.
                </h3>
                <h3 id='tmj-paragraph'>
                5. <strong>Stress and muscle tension:</strong> Emotional or psychological stress can cause increased muscle tension in the jaw and facial region. Chronic muscle tension can strain the temporomandibular joint and contribute to TMJ disorder.
                </h3>
                <h3 id='tmj-paragraph'>
                6. <strong>Malocclusion (improper bite):</strong> An improper bite, where the upper and lower teeth do not align correctly, can place excessive stress on the temporomandibular joint. Over time, this can lead to TMJ disorder.
                </h3>
                <h3 id='tmj-paragraph'>
                7. <strong>Poor posture:</strong> Slouching or maintaining poor posture can affect the alignment of the spine, neck, and jaw. This misalignment can contribute to TMJ disorder by placing stress on the jaw joint.
                </h3>
                <h3 id='tmj-paragraph'>
                8. <strong>Hormonal factors:</strong> Some studies suggest that hormonal changes, such as those occurring during pregnancy or menopause, may influence the development or worsening of TMJ disorder symptoms. However, further research is needed to understand this relationship better.
                </h3>
                <h3 id='tmj-paragraph'>
                It's important to note that these factors can interact and contribute to the development of TMJ disorder. If you suspect you have TMJ disorder, it's advisable to consult a healthcare professional, such as a dentist or an oral and maxillofacial specialist, for an accurate diagnosis and appropriate treatment.
                </h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography><h1 id="accord-title">Treatment</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>The treatment for TMJ disorder (TMD) can vary depending on the severity of the symptoms and the underlying causes. Here are some common approaches to treating TMJ disorder:
</h3>
<h3 id='tmj-paragraph'>
1. <strong>Self-Care and Lifestyle Changes:</strong>
   - Applying ice packs or moist heat to the affected area to alleviate pain and reduce inflammation.
   - Eating soft foods and avoiding hard or chewy foods that may strain the jaw.
   - Avoiding activities that worsen symptoms, such as excessive jaw movements, gum chewing, or biting nails.
   - Practicing stress management techniques, such as relaxation exercises or counseling, to reduce jaw tension caused by stress.
   </h3>
<h3 id='tmj-paragraph'>
2. <strong>Medications:</strong>
   - Over-the-counter pain relievers: Nonsteroidal anti-inflammatory drugs (NSAIDs), such as ibuprofen, can help reduce pain and inflammation associated with TMJ disorder.
   - Muscle relaxants: In some cases, short-term use of muscle relaxants can help relieve muscle spasms and associated pain.
   </h3>
<h3 id='tmj-paragraph'>
3. <strong>Oral Appliances:</strong>
   - Splints or mouthguards: These devices are custom-made to fit over the teeth and help stabilize the jaw joint, reduce grinding or clenching, and alleviate pain.
   </h3>
<h3 id='tmj-paragraph'>
4. <strong>Physical Therapy:</strong>
   - Jaw exercises: A physical therapist or dentist specializing in TMD may recommend specific exercises to strengthen and stretch the jaw muscles, improving jaw function and reducing pain.
   - Transcutaneous electrical nerve stimulation (TENS): This therapy involves the use of low-level electrical currents to stimulate the muscles and provide pain relief.
   - Ultrasound therapy: High-frequency sound waves are used to alleviate pain and promote healing in the affected area.
   </h3>
<h3 id='tmj-paragraph'>
5. <strong>Dental Treatments:</strong>
   - Bite adjustments: If TMJ disorder is caused by a misaligned bite, dental treatments like orthodontic adjustments or reshaping of teeth surfaces may be recommended to improve the alignment and relieve symptoms.
   - Dental restorations: In some cases, replacing missing teeth or correcting dental irregularities can help improve the bite and alleviate TMJ disorder symptoms.
   </h3>
<h3 id='tmj-paragraph'>
6. <strong>Injections:</strong>
   - Corticosteroid injections: In severe cases, corticosteroid injections directly into the jaw joint can help reduce inflammation and provide temporary pain relief.
   - Botox Injections in cases where overactive and tight muscles are the cause of jaw pain.
   </h3>
<h3 id='tmj-paragraph'>
7. <strong>Surgery (in rare cases):</strong>
   - Arthrocentesis: This minimally invasive procedure involves flushing out the temporomandibular joint with sterile fluids to remove debris and reduce inflammation.
   - Arthroscopy: A small surgical instrument is inserted into the joint to remove or repair damaged tissue.
   - Open-joint surgery: In rare cases, open-joint surgery may be necessary to repair or replace the temporomandibular joint.

It's important to consult a healthcare professional, such as a dentist or an oral and maxillofacial specialist, to determine the most appropriate treatment plan for your specific case of TMJ disorder. Treatment may involve a combination of approaches tailored to your individual needs.
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography><h1 id="accord-title">Symptoms</h1></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <h3 id='tmj-paragraph'>Temporomandibular joint (TMJ) disorder refers to a condition that affects the temporomandibular joint, which connects your jawbone to your skull. TMJ disorder can cause a variety of symptoms, which may vary from person to person. Here are some common symptoms associated with TMJ disorder:
    </h3>
<h3 id='tmj-paragraph'>
1. <strong>Jaw pain:</strong> This is one of the primary symptoms of TMJ disorder. You may experience pain or discomfort in the jaw joint, which is located just in front of your ears on both sides of your face. The pain can be persistent or occur intermittently.
</h3>
<h3 id='tmj-paragraph'>
2. <strong>Jaw clicking or popping:</strong> You might notice a clicking, popping, or grating sound when you open or close your mouth. This sound can originate from the jaw joint itself.
</h3>
<h3 id='tmj-paragraph'>
3. <strong>Difficulty in opening or closing the mouth:</strong> Some individuals with TMJ disorder find it challenging to fully open or close their mouths. This limitation in jaw movement can be accompanied by pain or a sensation of jaw stiffness.
</h3>
<h3 id='tmj-paragraph'>
4. <strong>Facial pain:</strong> TMJ disorder can cause pain in the face, particularly around the jaw joint, temples, cheeks, or in front of the ear. The pain may be dull or sharp and can be intermittent or constant.
</h3>
<h3 id='tmj-paragraph'>
5. <strong>Headaches:</strong> Many people with TMJ disorder experience headaches, often described as tension-type headaches or migraines. The pain can radiate from the jaw and temples to other areas of the head.
</h3>
<h3 id='tmj-paragraph'>
6. <strong>Ear pain or fullness:</strong> TMJ disorder can lead to ear-related symptoms, such as ear pain, a feeling of fullness or pressure in the ears, tinnitus (ringing in the ears), or temporary hearing loss. These symptoms can be mistakenly attributed to ear problems.
</h3>
<h3 id='tmj-paragraph'>
7. <strong>Facial swelling:</strong> In some cases, TMJ disorder can cause facial swelling, particularly around the jaw joint.
</h3>
<h3 id='tmj-paragraph'>
8. <strong>Bite problems:</strong> TMJ disorder may affect the way your upper and lower teeth fit together, leading to bite problems or changes in your dental occlusion. This can cause discomfort while biting or chewing, and in some cases, result in an uneven bite.
</h3>
<h3 id='tmj-paragraph'>
It's important to note that these symptoms can also be caused by other conditions, so it's essential to consult a healthcare professional, such as a dentist or an oral and maxillofacial specialist, for an accurate diagnosis and appropriate treatment.
</h3>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
    </div>
    <div className='tmj-page'>
        {/* <h1 id='tmj-header1'>What is TMJ?</h1>
        <h3 id='tmj-paragraph'>"TMJ" are the first letters of the three anatomic parts of the head.
T= Temporal bone (skull), M= Mandible (lower jaw), J= Joint (connects two bones together) and called temporo-mandibular-joint.
</h3>
<h3 id='tmj-paragraph'>
The TMJ is one of the most complex joints in the body. It is composed of a large group of delicate small muscles, nerves, blood vessels, ligaments, tendons, cartilage, disc, synovial fluid, retrodiscal tissue: each part works in harmony with other parts much like a symphony orchestra.
</h3>
<h3 id='tmj-paragraph'>
All body joints (neck, shoulder, back, hip, knee, wrist, elbow, ankle, finger) have hinge movement or circular movement or a combination of both. However, TMJ has not only hinge and circular movement, it also has a gliding movement, in and out of the socket (glenoid fossa): we call it "Ginglymoarthroidal joint".
The function of the TMJ is to hold the lower jaw to the head within a certain boundary, and guide the jaw movements for chewing, talking, singing, yawning, breathing, swallowing, keeping the airway open while sleeping, and maintaining body posture and balance, etc.

</h3>
<h1 id='tmj-header2'>Causes</h1>
<h3 id='tmj-paragraph'>
The exact causes of TMJ disorder are often multifactorial and can vary from person to person. Here are some common factors that can contribute to the development of TMJ disorder:</h3>
<h3 id='tmj-paragraph'>
1. <strong>Jaw misalignment:</strong> Problems with the alignment or positioning of the jaw joint (temporomandibular joint) can lead to TMJ disorder. This misalignment can occur due to factors like trauma or injury to the jaw, improper dental work, or developmental abnormalities.
</h3>
<h3 id='tmj-paragraph'>
2. <strong>Jaw clenching and teeth grinding (bruxism):</strong> Persistent clenching or grinding of the teeth, which often occurs during sleep, can exert excessive pressure on the jaw joint. Over time, this can contribute to the development of TMJ disorder.
</h3>
<h3 id='tmj-paragraph'>
3. <strong>Arthritis:</strong> Various forms of arthritis, such as osteoarthritis or rheumatoid arthritis, can affect the temporomandibular joint and lead to TMJ disorder. Arthritis causes inflammation and damage to the joint structures, resulting in pain and dysfunction.
</h3>
<h3 id='tmj-paragraph'>
4. <strong>Trauma or injury:</strong> A direct impact or injury to the jaw area, such as from a sports injury, motor vehicle accident, or a fall, can damage the temporomandibular joint or surrounding structures, leading to TMJ disorder.
</h3>
<h3 id='tmj-paragraph'>
5. <strong>Stress and muscle tension:</strong> Emotional or psychological stress can cause increased muscle tension in the jaw and facial region. Chronic muscle tension can strain the temporomandibular joint and contribute to TMJ disorder.
</h3>
<h3 id='tmj-paragraph'>
6. <strong>Malocclusion (improper bite):</strong> An improper bite, where the upper and lower teeth do not align correctly, can place excessive stress on the temporomandibular joint. Over time, this can lead to TMJ disorder.
</h3>
<h3 id='tmj-paragraph'>
7. <strong>Poor posture:</strong> Slouching or maintaining poor posture can affect the alignment of the spine, neck, and jaw. This misalignment can contribute to TMJ disorder by placing stress on the jaw joint.
</h3>
<h3 id='tmj-paragraph'>
8. <strong>Hormonal factors:</strong> Some studies suggest that hormonal changes, such as those occurring during pregnancy or menopause, may influence the development or worsening of TMJ disorder symptoms. However, further research is needed to understand this relationship better.
</h3>
<h3 id='tmj-paragraph'>
It's important to note that these factors can interact and contribute to the development of TMJ disorder. If you suspect you have TMJ disorder, it's advisable to consult a healthcare professional, such as a dentist or an oral and maxillofacial specialist, for an accurate diagnosis and appropriate treatment.
</h3>

<h1 id='tmj-header3'>Treatment</h1>
<h3 id='tmj-paragraph'>The treatment for TMJ disorder (TMD) can vary depending on the severity of the symptoms and the underlying causes. Here are some common approaches to treating TMJ disorder:
</h3>
<h3 id='tmj-paragraph'>
1. <strong>Self-Care and Lifestyle Changes:</strong>
   - Applying ice packs or moist heat to the affected area to alleviate pain and reduce inflammation.
   - Eating soft foods and avoiding hard or chewy foods that may strain the jaw.
   - Avoiding activities that worsen symptoms, such as excessive jaw movements, gum chewing, or biting nails.
   - Practicing stress management techniques, such as relaxation exercises or counseling, to reduce jaw tension caused by stress.
   </h3>
<h3 id='tmj-paragraph'>
2. <strong>Medications:</strong>
   - Over-the-counter pain relievers: Nonsteroidal anti-inflammatory drugs (NSAIDs), such as ibuprofen, can help reduce pain and inflammation associated with TMJ disorder.
   - Muscle relaxants: In some cases, short-term use of muscle relaxants can help relieve muscle spasms and associated pain.
   </h3>
<h3 id='tmj-paragraph'>
3. <strong>Oral Appliances:</strong>
   - Splints or mouthguards: These devices are custom-made to fit over the teeth and help stabilize the jaw joint, reduce grinding or clenching, and alleviate pain.
   </h3>
<h3 id='tmj-paragraph'>
4. <strong>Physical Therapy:</strong>
   - Jaw exercises: A physical therapist or dentist specializing in TMD may recommend specific exercises to strengthen and stretch the jaw muscles, improving jaw function and reducing pain.
   - Transcutaneous electrical nerve stimulation (TENS): This therapy involves the use of low-level electrical currents to stimulate the muscles and provide pain relief.
   - Ultrasound therapy: High-frequency sound waves are used to alleviate pain and promote healing in the affected area.
   </h3>
<h3 id='tmj-paragraph'>
5. <strong>Dental Treatments:</strong>
   - Bite adjustments: If TMJ disorder is caused by a misaligned bite, dental treatments like orthodontic adjustments or reshaping of teeth surfaces may be recommended to improve the alignment and relieve symptoms.
   - Dental restorations: In some cases, replacing missing teeth or correcting dental irregularities can help improve the bite and alleviate TMJ disorder symptoms.
   </h3>
<h3 id='tmj-paragraph'>
6. <strong>Injections:</strong>
   - Corticosteroid injections: In severe cases, corticosteroid injections directly into the jaw joint can help reduce inflammation and provide temporary pain relief.
   - Botox Injections in cases where overactive and tight muscles are the cause of jaw pain.
   </h3>
<h3 id='tmj-paragraph'>
7. <strong>Surgery (in rare cases):</strong>
   - Arthrocentesis: This minimally invasive procedure involves flushing out the temporomandibular joint with sterile fluids to remove debris and reduce inflammation.
   - Arthroscopy: A small surgical instrument is inserted into the joint to remove or repair damaged tissue.
   - Open-joint surgery: In rare cases, open-joint surgery may be necessary to repair or replace the temporomandibular joint.

It's important to consult a healthcare professional, such as a dentist or an oral and maxillofacial specialist, to determine the most appropriate treatment plan for your specific case of TMJ disorder. Treatment may involve a combination of approaches tailored to your individual needs.
</h3> */}
    {/* <h1 id='tmj-header4'>Symptoms</h1>
    <h3 id='tmj-paragraph'>Temporomandibular joint (TMJ) disorder refers to a condition that affects the temporomandibular joint, which connects your jawbone to your skull. TMJ disorder can cause a variety of symptoms, which may vary from person to person. Here are some common symptoms associated with TMJ disorder:
    </h3>
<h3 id='tmj-paragraph'>
1. <strong>Jaw pain:</strong> This is one of the primary symptoms of TMJ disorder. You may experience pain or discomfort in the jaw joint, which is located just in front of your ears on both sides of your face. The pain can be persistent or occur intermittently.
</h3>
<h3 id='tmj-paragraph'>
2. <strong>Jaw clicking or popping:</strong> You might notice a clicking, popping, or grating sound when you open or close your mouth. This sound can originate from the jaw joint itself.
</h3>
<h3 id='tmj-paragraph'>
3. <strong>Difficulty in opening or closing the mouth:</strong> Some individuals with TMJ disorder find it challenging to fully open or close their mouths. This limitation in jaw movement can be accompanied by pain or a sensation of jaw stiffness.
</h3>
<h3 id='tmj-paragraph'>
4. <strong>Facial pain:</strong> TMJ disorder can cause pain in the face, particularly around the jaw joint, temples, cheeks, or in front of the ear. The pain may be dull or sharp and can be intermittent or constant.
</h3>
<h3 id='tmj-paragraph'>
5. <strong>Headaches:</strong> Many people with TMJ disorder experience headaches, often described as tension-type headaches or migraines. The pain can radiate from the jaw and temples to other areas of the head.
</h3>
<h3 id='tmj-paragraph'>
6. <strong>Ear pain or fullness:</strong> TMJ disorder can lead to ear-related symptoms, such as ear pain, a feeling of fullness or pressure in the ears, tinnitus (ringing in the ears), or temporary hearing loss. These symptoms can be mistakenly attributed to ear problems.
</h3>
<h3 id='tmj-paragraph'>
7. <strong>Facial swelling:</strong> In some cases, TMJ disorder can cause facial swelling, particularly around the jaw joint.
</h3>
<h3 id='tmj-paragraph'>
8. <strong>Bite problems:</strong> TMJ disorder may affect the way your upper and lower teeth fit together, leading to bite problems or changes in your dental occlusion. This can cause discomfort while biting or chewing, and in some cases, result in an uneven bite.
</h3>
<h3 id='tmj-paragraph'>
It's important to note that these symptoms can also be caused by other conditions, so it's essential to consult a healthcare professional, such as a dentist or an oral and maxillofacial specialist, for an accurate diagnosis and appropriate treatment.
</h3> */}
</div>
<div id='scroll-up-button'>
<FaArrowCircleUp onClick={scrollToTop}/>
</div>
        <Footer />
        </>
    );
}

export default Tmj_info; 